import React, { useState, useContext, createContext } from 'react';

import { Container, Header, Question, Answear } from './styles/accordion';
import { GrFormAdd } from 'react-icons/gr';
import { BiMinus } from 'react-icons/bi';

const ToggleContext = createContext();

export default function Accordion({ children, ...restProps }) {
  const [toggleShow, setToggleShow] = useState(false);

  return (
    <ToggleContext.Provider value={{ toggleShow, setToggleShow }}>
      <Container {...restProps}>{children}</Container>
    </ToggleContext.Provider>
  );
}

Accordion.Question = function AccordionQuestion({ children, ...restProps }) {
  const { toggleShow, setToggleShow } = useContext(ToggleContext);

  return (
    <Header onClick={() => setToggleShow(!toggleShow)} {...restProps}>
      <Question>{children}</Question>
      {!toggleShow ? <GrFormAdd /> : <BiMinus />}
    </Header>
  );
};

Accordion.Answear = function AccordionAnswear({ children, ...restProps }) {
  const { toggleShow } = useContext(ToggleContext);

  return toggleShow ? <Answear {...restProps}>{children}</Answear> : null;
};
