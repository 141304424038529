import React from 'react';
import { Section } from '../components';
import Accordion from '../components/accodrion';

function SolutionContainer({
  caption,
  title,
  image,
  subTitleOne,
  textOne,
  subTitleTwo,
  textTwo,
  textThree,
  faq,
}) {
  return (
    <Section>
      <Section.Container>
        <Section.Caption>{caption}</Section.Caption>
        <Section.Title>{title}</Section.Title>
        <Section.Content direction="row-reverse">
          <Section.Pane width="60">
            {subTitleOne ? (
              <Section.Caption>{subTitleOne}</Section.Caption>
            ) : null}
            {textOne ? <Section.Text>{textOne}</Section.Text> : null}
            {subTitleTwo ? (
              <Section.Caption>{subTitleTwo}</Section.Caption>
            ) : null}
            {textTwo ? <Section.Text>{textTwo}</Section.Text> : null}
            {textThree ? <Section.Text>{textThree}</Section.Text> : null}
            {faq
              ? faq.map(({ node: { id, question, answear } }) => {
                  return (
                    <Accordion key={id}>
                      <Accordion.Question>{question}</Accordion.Question>
                      <Accordion.Answear>{answear.answear}</Accordion.Answear>
                    </Accordion>
                  );
                })
              : null}
          </Section.Pane>
          <Section.Pane width="40">
            {image ? <Section.Picture fluid={image} alt={title} /> : null}
          </Section.Pane>
        </Section.Content>
      </Section.Container>
    </Section>
  );
}

export default SolutionContainer;
