import React from 'react';
import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import SolutionContainer from '../containers/solutionContainer';
import { graphql, useStaticQuery } from 'gatsby';

export default function ResidentialPage() {
  const data = useStaticQuery(graphql`
    query {
      residential: file(relativePath: { eq: "residential_image.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Layout defaultColor={({ theme }) => theme.colors.lightBg} fixed={false}>
      <SEO
        title="Residential"
        description="One of our professional exterminating experts will come to your home or office in the greater St. Louis area, and solve all your bed bug problems at a time that is convenient for you!  Simply choose an available date and time from the calendar below and request a Free Inspection."
      />
      <SolutionContainer
        caption="our solutions"
        title="Residential Pest Control"
        subTitleOne="Free Home Inspection"
        textOne="If you have found evidence of a pest problem -- droppings, nests, or any other evidence that suggests you have unwanted guests in your home -- give us a call right away at (314) 558 - 4847, or make an appointment online and choose a time that is best for you, and we will send a certified inspector to your home to inspect and assess your property completely Free Of Charge."
        subTitleTwo="Treatment"
        textTwo="Once all the problem and potential problem areas of your home have been inspected, we can create a customized treatment program based on your specific needs. Treatment programs range from Monthly to Quarterly to one time treatments. Most pest control programs in St. Louis and St. Charles areas are quarterly. These programs consist of both Mechanical pest control methods such as exclusion and trapping, to safe chemical applications to reduce and eliminate your pest problems. There is no pest problem we can't handle!"
        image={data.residential.childImageSharp.fluid}
      />
    </Layout>
  );
}
