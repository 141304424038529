import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 65rem;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.lightBg};
  border: 0.1rem solid ${({ theme }) => theme.colors.greyText}1A;
  box-shadow: ${({ theme }) => theme.shadows.shadowThree};
  border-radius: 0.5rem;
  margin: 0.8rem 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > svg {
    font-size: 2.2rem;
  }
`;

export const Question = styled.h4`
  font-size: ${({ theme }) => theme.typography.h4.size};
  font-weight: 500;
  width: 95%;
`;

export const Answear = styled.p`
  padding: 2rem 0 1rem;
  font-size: 1.8rem;
  line-height: 2.3rem;
  width: 98%;
`;
